import React from "react";

// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconMail,
  IconCalendar,
  IconNfc,
  IconBuildingStore,
} from "@tabler/icons";
import { getDecryptDataFromLocal } from "../../utils/encrypt";

// constant
const icons = {
  IconUserCheck: IconUserCheck,
  IconBasket: IconBasket,
  IconMessages: IconMessages,
  IconMail: IconMail,
  IconCalendar: IconCalendar,
  IconNfc: IconNfc,
  IconBuildingStore: IconBuildingStore,
};

//-----------------------|| Customer MENU ITEMS ||-----------------------//
var role = getDecryptDataFromLocal('setRole')
let phoneNumber = getDecryptDataFromLocal('phoneNumber')
let item = []

const sellerCreateAccess =  ['7827815759','9560382051','8882954273','7022888630','9719836187','8178277221','7065923736','9667530528','8920702782']

if (role == 10 || role == 1) {
  item = [
    // {
    //   id: "AdminRegisters",
    //   title: <FormattedMessage id="User Register" />,
    //   type: "item",
    //   url: "/admin/adminregister",
    // },
    // {
    //   id: 'Product Concern',
    //   title: <FormattedMessage id='Product Concern' />,
    //   type: 'item',
    //   url: '/admin/productconcern'
    // },
    {
      id: 'Edit Category',
      title: <FormattedMessage id='Edit Category' />,
      type: 'item',
      url: '/admin/editcategory'
    },
    {
      id: 'Edit Leaf Category',
      title: <FormattedMessage id='Edit Leaf Category' />,
      type: 'item',
      url: '/admin/editleafcategory'
    },
    {
      id: 'Create Coupon',
      title: <FormattedMessage id='Create Coupon' />,
      type: 'item',
      url: '/admin/createcoupon'
    },
    {
      id: 'Notification',
      title: <FormattedMessage id='Notification' />,
      type: 'item',
      url: '/admin/notification'
    },
    {
      id: 'Create New Salon',
      title: <FormattedMessage id='Create New Salon' />,
      type: 'item',
      url: '/admin/create-new-salon'
    },
    {
      id: 'Add Survey Questions',
      title: <FormattedMessage id='Add Survey Questions' />,
      type: 'item',
      url: '/admin/add-survey-questions'
    }]
} else if (role == 7 || role == 16) {
  item = [
    // {
    //   id: "AdminRegisters",
    //   title: <FormattedMessage id="User Register" />,
    //   type: "item",
    //   url: "/admin/adminregister",
    // },
    // {
    //   id: 'Product Concern',
    //   title: <FormattedMessage id='Product Concern' />,
    //   type: 'item',
    //   url: '/admin/productconcern'
    // },
  ]
} else if (role == 2) {
  if (phoneNumber == "9811415389") {
    item = [
      // {
      //   id: "AdminRegisters",
      //   title: <FormattedMessage id="User Register" />,
      //   type: "item",
      //   url: "/admin/adminregister",
      // },
      {
        id: 'Notification',
        title: <FormattedMessage id='Notification' />,
        type: 'item',
        url: '/admin/notification'
      },
      {
        id: 'Add Survey Questions',
        title: <FormattedMessage id='Add Survey Questions' />,
        type: 'item',
        url: '/admin/add-survey-questions'
      },
    ]
  } else if (phoneNumber == "9907125180") {
    item = [
      {
        id: 'Salons Whatsapp Config',
        title: <FormattedMessage id='Salons Whatsapp Config' />,
        type: 'item',
        url: '/admin/salons-whatsapp-config'
      }
    ]
  }

}

if (role == 1 || phoneNumber == "8076096127" || phoneNumber=='9560128741' ||phoneNumber=='9811068834') {
  item.push({
    id: "AdminRegisters",
    title: <FormattedMessage id="User Register" />,
    type: "item",
    url: "/admin/adminregister",
  })
}

if (phoneNumber == "9177139113" || phoneNumber == "9911034338" || phoneNumber == "9719836187" || phoneNumber == "9999152580") {
  item.push({
    id: 'Agent Tikona Config',
    title: <FormattedMessage id='Agent Tikona Config' />,
    type: 'item',
    url: '/admin/agen-tikona-config'
  })
}
if (phoneNumber == "9177139113") {
  item.push({
    id: 'Salons Whatsapp Config',
    title: <FormattedMessage id='Salons Whatsapp Config' />,
    type: 'item',
    url: '/admin/salons-whatsapp-config'
  })
}
if(sellerCreateAccess.includes(phoneNumber)){
item.push(
  {
    id: 'Seller Register',
    title: <FormattedMessage id='Seller Register' />,
    type: 'item',
    url: '/admin/sellerRegister'
  }
  )
}
// else {
//   item = [
//     {
//       id: "AdminRegisters",
//       title: <FormattedMessage id="User Register" />,
//       type: "item",
//       url: "/admin/adminregister",
//     },
//   ]
// }

export const AdminTab = {
  id: "Admin",
  title: <FormattedMessage id="Admin" />,
  type: "group",
  children: [
    {
      id: "Admins",
      title: <FormattedMessage id="Admin" />,
      type: "collapse",
      icon: icons["IconBuildingStore"],
      children: item
    },
  ],
};
