import React, { useEffect, useState } from "react";
import SubCard from "../../ui-component/cards/SubCard";
import {
  Box,
  Button,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import axios from "axios";
import { Table, TableContainer, TablePagination } from "@material-ui/core";
import { numericCommaSeparation } from "../../ui-component/NumericSeperation";
import moment from "moment";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { LoadingButton } from "@material-ui/lab";

function ProcessingOrder() {
  const [date, setDate] = useState({
    startDate: moment().clone().startOf("month").format("YYYY-MM-DD"),
    endDate: new Date().toISOString().slice(0, 10),
  });
  const [data, setdata] = React.useState([]);
  const [allEANCodeQuantity, setAllEANCodeQuantity] = React.useState([]);
  const [buttonDisable, setButtonDisable] = React.useState(true);
  const [totalPayAmount, setTotalPayAmount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [totalpage, setTotalPage] = useState();
  const [loading, setLoading] = useState(false);
  const checkUcInventory = async (EANCode) => {
    axios
      .get(`${process.env.REACT_APP_PATH}/products/getAllUcInventory`)
      .then((res) => {
        if (res.data.success) {
          if (res.data.data) {
            setAllEANCodeQuantity(res.data.data);
            setButtonDisable(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err?.response?.data?.message || "something went wrong");
      });
  };

  const handleSearch = (cp = page, limit = rowsPerPage) => {
    axios
      .post(`${process.env.REACT_APP_PATH}/sales/getBulkProcessingOrders`, {
        startDate: date.startDate,
        endDate: date.endDate,
        page: cp,
        limit: limit,
      })
      .then((res) => {
        if (res.data.success) {
          let tempTotal = 0;
          let tempData = res.data.data[0].data;
          setTotalPage(res.data.data[0].metaData[0].totalDocuments);
          tempData.forEach((item) => {
            let totalSumOfInventory = 0;
            let totalSumOfQuantity = 0;
            let totalSumWithFlorida = 0;
            let totalAvailableQuantity = 0
            item.products.forEach((product) => {
              product.availableInventory =
                allEANCodeQuantity[product.EANCode] || 0;

              totalSumOfInventory +=allEANCodeQuantity[product.EANCode] >= product.quantity? product.quantity * product.price: (allEANCodeQuantity[product.EANCode]||0) * product.price;
              totalSumOfQuantity +=product.quantity
              totalAvailableQuantity+=( allEANCodeQuantity[product.EANCode]>=product.quantity?product.quantity:(allEANCodeQuantity[product.EANCode] || 0 ));
              // if(!['65447bd2c93023787fedfa7c','6539e5f4ccd18dd0dbc4dc79'].includes(product.brandId)){
              totalSumWithFlorida +=
                allEANCodeQuantity[product.EANCode] >= product.quantity
                  ? product.quantity * product.price
                  : (allEANCodeQuantity[product.EANCode]||0) * product.price;
              // }
            });
            item.totalSum = totalSumOfInventory;
            tempTotal += item.totalPayableAmount;


            item.percentageOfAmount = Math.ceil((totalAvailableQuantity / totalSumOfQuantity) * 100);
          });
          let sortData = tempData.sort(
            (a, b) => b.percentageOfAmount - a.percentageOfAmount
          );
          setdata(sortData);
          setTotalPayAmount(tempTotal);
          setLoading(false);
        } else {
          alert(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err?.response?.data?.message || "something went wrong");
        setLoading(false);
      });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    handleSearch("", +event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setRowsPerPage(rowsPerPage);
    handleSearch(newPage, rowsPerPage);
  };

  useEffect(() => {
    checkUcInventory();
  }, []);

  function CollapesRow(props) {
    const { item } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>{item?.name || ""}</TableCell>
          <TableCell>{item?.numericOrderId || ""}</TableCell>
          <TableCell>
            {item?.parentBulkNumericOrderId || "Not Splited"}
          </TableCell>
          <TableCell>{item?.phoneNumber || ""}</TableCell>
          <TableCell>
            {numericCommaSeparation(Math.round(item?.totalPayableAmount || 0))}
          </TableCell>
          <TableCell>
            {numericCommaSeparation(Math.round(item?.totalSum || 0))}
          </TableCell>
          <TableCell>{item?.percentageOfAmount || 0}%</TableCell>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Available Quantity On Uc</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item?.products &&
                      item?.products &&
                      item?.products.map((pro) => (
                        <TableRow
                          style={{
                            backgroundColor:
                              pro.availableInventory < pro.quantity
                                ? "#db840f"
                                : "white",
                            color: "#000",
                          }}
                        >
                          <TableCell>{pro.name}</TableCell>
                          <TableCell>{pro.quantity}</TableCell>
                          <TableCell>{pro.price}</TableCell>
                          <TableCell>{pro.availableInventory}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <SubCard>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item lg={3}>
          <TextField
            id="datetime-local"
            label="Start Date"
            type="date"
            defaultValue={date.startDate}
            InputLabelProps={{
              shrink: true,
            }}
            style={{
              width: "100%",
            }}
            onChange={(e) =>
              setDate({
                ...date,
                startDate: e.target.value,
              })
            }
            fullWidth
          />
        </Grid>
        <Grid item lg={3}>
          <TextField
            id="datetime-local"
            label="End Date"
            type="date"
            defaultValue={date.endDate}
            InputLabelProps={{
              shrink: true,
            }}
            style={{
              width: "100%",
            }}
            onChange={(e) =>
              setDate({
                ...date,
                endDate: e.target.value,
              })
            }
            fullWidth
          />
        </Grid>
        <Grid item lg={3}>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={() => handleSearch(page, rowsPerPage)}
            fullWidth
            loading={loading}
            loadingIndicator="Loading..."
            disabled={buttonDisable}
          >
            Search
          </LoadingButton>
        </Grid>
      </Grid>
      <Grid container lg={12} spacing={2} mt={2}>
        {/* <Grid item lg={3} md={3}>
          <TextField
            label="Customer Name"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item lg={3} md={3}>
          <TextField
            label="Invoice Id / Order Id"
            value={InvoiceId}
            onChange={(e) => setInvoiceId(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item lg={3} md={3}>
          <TextField
            label="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item lg={3} md={3}>
          <TextField
            label="Agent Name"
            value={agentName}
            onChange={(e) => setAgentName(e.target.value)}
            fullWidth
          />
        </Grid> */}
        {/* <Grid item lg={3}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: 20 }}
            onClick={handleSearch}
            fullWidth
          >
            Search
          </Button>
        </Grid> */}
      </Grid>
      <Grid container>
        <TableContainer style={{ marginTop: 20, maxHeight: 600 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Numeric Order Id</TableCell>
                <TableCell>Parent Order Id</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>
                  Total Payable (
                  {numericCommaSeparation(Math.round(totalPayAmount || 0))})
                </TableCell>
                <TableCell>Available</TableCell>
                <TableCell>Percentage Of Quantity</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((item, idx) => {
                  return <CollapesRow item={item} />;
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100, 200]}
        component="div"
        count={totalpage ? totalpage : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </SubCard>
  );
}

export default ProcessingOrder;

// <TableRow key={idx}>
//   <TableCell>{item?.name || ""}</TableCell>
//   <TableCell>{item?.phoneNumber || ""}</TableCell>
//   <TableCell>
//     {numericCommaSeparation(item?.totalPayableAmount || 0)}
//   </TableCell>
//   <TableCell>{item?.totalSum || ""}</TableCell>
//   <TableCell>
//     {Math.round(
//       (item?.totalSum / item.totalPayableAmount) * 100
//     ) || 0}
//     %
//   </TableCell>
// </TableRow>
